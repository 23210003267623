import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ActionModalDefault from '@myaccount/modals/default';
import DialogChildren from './dialogChildren';
import CircularProgress from '@myaccount/progress/CircularProgress';
import { usePropertyActionState } from "@myaccountServices";
import defaults from './defaults';

const CreateTenancyBtn = withSubtheme(props => {
    const {
        // btnLabelProps,
        unarchived,
        // btnLabel,
        // btnProps,
        property,
        listType,
        reloadDataFun,
        maxDialogW,
        // openModal,
        // openRoomDetailsFun,
        openRoomDetails,
        // setOpenRoomDetails,
        // link,
    } = props

    let {
        modalTitle,
        modalSubTitle
    } = props;

    // const label = btnLabel ? btnLabel : btnLabelProps;

    const { state, services } = usePropertyActionState()
    const [open, setOpen] = React.useState(openRoomDetails || false);
    const [canReloadData, setCanReloadData] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    // if form success then reloadData
    if (state.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        setOpen(false);
        services.updateState({success: false})
    };

    const handleDelete = (value) => {
        let apiParams = { property_id:props.property.crm_id }
        if ( listType === 'unarchived' )
            services.unHideProperty(apiParams)
        else
            services.hideProperty(apiParams)
    };

    if (reloadDataFun && canReloadData) {
        reloadDataFun();
    }
    if (property?.reloadDataFun && canReloadData) {
        property.reloadDataFun()
    }

    //
    if (!property.show_hide_button && listType !== 'unarchived')
        return null

    // change title if it is unarchived
    if (listType === 'unarchived') {
        modalTitle = unarchived.modalTitle
        modalSubTitle = unarchived.modalSubTitle
    }

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <>
            {modalSubTitle && modalSubTitle}
            <DialogChildren
                handleClose={handleClose}
                handleDelete={handleDelete}
                state={state}
            />
        </>
    }


    return(
        <>
            <IconButton aria-label="Remove property" onClick={()=>{
                handleClickOpen()
            }}>
                <CloseIcon />
            </IconButton>
            <ActionModalDefault
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                property={property}
                listType={listType}
                children={dialogContent}
                loading={state.loading}
                success={state?.success}
                title={modalTitle ? modalTitle : property?.display_address}
                maxDialogWidth={maxDialogW}
            />
        </>
    )
}, 'removePropertyPersona', defaults)

export default CreateTenancyBtn;
