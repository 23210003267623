import React from "react";
import { get } from "lodash";
import {
    withSubtheme
} from '@starberryComponentsMui';

import defaults from './defaults';

const AreaInfo = withSubtheme(({property}) => {
    let areaAddress = property?.area_address
    if (get(property, 'address')) {
        let newAddress = [];
        const address = property?.address;
        if (address?.area)
            newAddress.push(address.area);
        if (address?.town)
            newAddress.push(address.town);

        if (newAddress.length)
            areaAddress = newAddress.join(', ')
    }
    return (
        <>
            {areaAddress}
        </>
    )
}, 'propertyAreaInfo', defaults)

export default AreaInfo
