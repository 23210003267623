import refactorObject from './refactorObject';
import { toTitleCase } from '../pages/properties/functions';
export const propertyClean = (property, theme) => {

    // Convert attribute names to a single known schema
    property = refactorObject(property, {
        "bedroom": "bedroom",
        "bathroom": "bathroom",
        "search_type": "search_type"
        // Add more attribute name mappings here, as needed.
    });

    // Clean up values of search_type, search_type, etc.:
    switch (property.search_type) {
    case 'sales':
    case 'Sales':
    case 'S':
    case 'for-sale':
    case 'for sale':
        property.search_type = 'for sale';
        break;

    case 'Lettings':
    case 'lettings':
    case 'L':
    case 'to-let':
    case 'to let':
        property.search_type = 'to let';
        break;

    default:
        console.warn(`Unknown value for property search_type: ${property.search_type}`);
        delete(property.search_type);
    }

    const replaceSpecialChar = (str) => {
        if (typeof str === 'array' || typeof str === 'object')
            return str.join(', ').replace(/[^\w\s]/gi, ' ');
        return str.replace(/[^\w\s]/gi, ' ');
    }

    // Generate a summary sentence
    // @todo this is probably inadequate; projects will want their own
    // sentences. If so, set summaryFn in the passed theme
    if (undefined === property.summary) {

        let summaryFn = theme?.getProp('summaryFn');
        if (undefined === summaryFn) {
            summaryFn = (property) => {
                const bedrooms = property.bedrooms;
                return [
                    (0===bedrooms ? 'Studio ' : 0>bedrooms ? bedrooms+' bedroom ' : ''),
                    (property.building ? (toTitleCase(replaceSpecialChar(property.building))) : ''),
                    (property.search_type==='for sale' ? 'for Sale' : property.search_type==='to let' ? 'to Let' : '')
                ].join(' ');
            }
        }

        property.summary = summaryFn(property);
    }

    return property;
};

export default propertyClean;
