import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useUserObjectState } from "@myaccountServices";
import { trackSaveProperty } from "@myaccount/helper/eventTracking";

export const RemoveProperty = (props) => {

    const { services } = useUserObjectState();

    const handleDelete = (id) => {
        services.deleteUserobject({id:id})
        trackSaveProperty({
            eventLabel: "Remove"
        });
    };

    return (
        <IconButton aria-label="Remove saved property" onClick={()=>{handleDelete(props.property.objectId)}}>
            <CloseIcon />
        </IconButton>
    );
}

export default RemoveProperty;
