export default {
    "container": {
        "direction": "column",
        "alignItems": "center",
        "alignContent": "center",
        "order": ['header', 'media', 'article', 'aside'],
        "wrap": "wrap",
        "container": true,
        // ie. <Grid container>
        "sx": {
        "display": "flex"
        }
    },
    "header": {},
    "article": {
        "direction": "column",
        "alignItems": "center",
        "alignContent": "center"
    },
    "media": {
        "group": "propertyresult", // Image group to use
        "position":"relative",
        "overflow":"hidden"

    },
    "aside": {},
    "address": {
        "in": "header",
        "variant": "subtitle1",
        "component": "header"
    },
    "summary": {
        "in": "article",
        "variant": "subtitle2",
        "component": "p"
    },
    "flag": {
        "in": "article",
        "variant": "subtitle2",
        "component": "p"
    },
    "description": {
        "in": "article",
        "variant": "subtitle1",
        "component": "p"
    },
    "price": {
        "in": "aside",
        "variant": "subtitle1",
        "component": "div"
    },
    "removePropertyPersona": {
        "in": "media",
        "sx": (theme) => ({
            "position": "absolute",
            "top": "10px",
            "right": "10px",
            "zIndex": "20",
            "& button": {
                "width": "36px",
                "height": "36px",
                "background": "white",
                "borderRadius": "100%",
                "& svg": {
                    "width": "18px",
                    "height": "18px",
                },
                "&:hover": {
                    "background": "linear-gradient(225deg, #1663B3 0%, #217698 100%)",
                    "& svg": {
                        "color": "#fff",
                    },
                }

            },
            [theme.breakpoints.down('sm')]:{
                "position": "absolute",
                "top": "3px",
                "right": "5px",
                "zIndex": "20",
                "& button": {
                    "width": "20px",
                    "height": "20px",
                    "background": "white",
                    "borderRadius": "100%",
                    "& svg": {
                        "width": "10px",
                        "height": "10px",
                    },
                    "&:hover": {
                        "background": "linear-gradient(225deg, #1663B3 0%, #217698 100%)",
                        "& svg": {
                            "color": "#fff",
                        },
                    }

                },
            }

        }),

    },
}
