/* eslint-disable import/first */

import React from "react";
import Grid from "@mui/material/Grid";
import { withSubtheme } from '@starberryComponentsMui';
import PropertyResult from '@myaccount/PropertyResult';
import Carousel from '@myaccount/carousel';

import defaults from './PropertyGridDefaults';

let c = 0;

const keysCache = {};

/*
    I suppose the unique key would be the property slug in case crm_id or objectID don't exist
    previously the counter just kept going up causing to always generate an id for a property even
    if it was in the previous search, which causes the search to be slow.

    At the time of writing the comment, as there is no typescript definition of what a property
    should be like, I don't have a precise idea about its structure. For that reason, I am betting on what I found
    in the result debugging which are: crm_id / objectID / maybe slug

    If a duplicate or the crm_id is no longer present in the property then change it to any of the identifier
    of the object
 */

const getFailOverKey = (uniqueKey) => {
    if (keysCache[uniqueKey]) {
        return keysCache[uniqueKey];
    }

    keysCache[uniqueKey] = c++;

    return keysCache[uniqueKey];
}

const getPropertyKey = property => {
    const propertyKey = property.key ?? property.source_reference ??
        property.id ?? property.crm_id ?? getFailOverKey(property.name);

    return propertyKey;
};

const RenderLayout = (props) => {
    const {
        layout,
        properties,
        image,
        listType,
        reloadDataFun
    } = props;


    switch (layout) {
        case "carousel":
            return(
                <Carousel>
                    {
                        properties.map(property => {
                            const propertyKey = getPropertyKey(property);

                            return (
                                <PropertyResult
                                    key={propertyKey}
                                    property={property}
                                    image_processed={image}
                                    listType={listType}
                                    reloadDataFun={reloadDataFun}
                                />
                            );
                        })
                    }
                </Carousel>
            )
        default:
            return (
                <React.Fragment>
                    {properties.map(property => {
                        const propertyKey = getPropertyKey(property);

                        return (
                            <PropertyResult
                                key={propertyKey}
                                property={property}
                                image_processed={image}
                                listType={listType}
                                reloadDataFun={reloadDataFun}
                            />
                        );
                    })}
                </React.Fragment>
            );
    }
}

const PropertyGrid = withSubtheme(props => {
    const {
        className,
        properties,
        resultsContainerProps,
        image_processed,
        layout,
        listType,
        reloadDataFun
    } = props

    return(
        <Grid {...resultsContainerProps} classes={{root: className}}>
            <RenderLayout layout={layout} properties={properties} image={image_processed} listType={listType} reloadDataFun={reloadDataFun}/>
        </Grid>
)}, 'results', defaults)


export default React.memo(PropertyGrid);
