export default {
    "props": {
        "layout": false,
        "resultsContainerProps": {
            "container": true,
            "direction": "row",
        },
    },
    "sx": {

    },
}