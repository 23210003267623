import React from 'react';
import IconButton from '@mui/material/IconButton';
import { MYACCOUNT_THEME } from "@myaccountUrls";
import { useSelector } from 'react-redux';


export const FlagBid = (props) => {
    const user = useSelector(state => state.profile);
    console.log("user => ", user);

    if( user?.profile?.approvedToBid || user?.user?.approvedToBid ) {

        const url = `/images/${MYACCOUNT_THEME}/bid-badge.svg`

        return (
            <>
                <IconButton>
                    <img src={url} alt="flagBidImg"/>
                </IconButton>
            </>
        );
    }

    return null;

}

export default FlagBid;
