import React from "react"
import { withSubtheme } from "@starberryComponentsMui"
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import defaults from "./dialogChildrenDefaults"

const DialogChildren = withSubtheme(props => {
    const { handleClose, handleDelete, state, btnProps} = props

    if (state.success) return null;

    return (
        <>
            <DialogActions>
                <Button {...btnProps} className="btn contact-btn" onClick={handleDelete}>Yes</Button>
                <Button {...btnProps} className="btn contact-btn" onClick={handleClose} autoFocus>
                    No
                </Button>
            </DialogActions>
        </>
    )
},"dialogChildren", defaults)

export default DialogChildren
