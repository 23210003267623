import React from 'react';
import Stack from "@mui/material/Stack"
import Skeleton from "@mui/material/Skeleton"
import AutoHeightSkeleton from "./AutoHeightSkeleton"

const DetailedPropertyCardSkeleton = () => (
    <Stack direction="column" spacing={2}>
        <AutoHeightSkeleton
            variant="rectangular"
            width="100%"
            aspectRatio={0.5625}
        />
        <Stack direction="column" spacing={1}>
            <Skeleton
                variant="text"
                width={180}
                height={30}
            />
            <Skeleton
                variant="text"
                width={200}
                height={25}
            />
            <Skeleton
                variant="text"
                width={170}
                height={20}
            />
            <Stack direction="row" spacing={1}>
                <Skeleton
                    variant="text"
                    width={80}
                    height={20}
                />
                <Skeleton
                    variant="text"
                    width={80}
                    height={20}
                />
                <Skeleton
                    variant="text"
                    width={80}
                    height={20}
                />
            </Stack>
        </Stack>
    </Stack>
);

export default DetailedPropertyCardSkeleton;
