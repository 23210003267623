/* eslint-disable import/first */

import React from 'react';
import clsx from 'clsx';
import { MYACCOUNT_LIGHT, GGFX_ENV } from "@myaccountUrls";
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform";
import { ShowProcessedImage } from '../../Common/ggfx-client/module/components/show-image';
import imageConfig from "../../../../static/images/config.json"
import { get } from 'lodash';

import {
    Flexbox,
    withSubtheme,
    deepmerge,
    AddressFormat,
    Image,
} from '@starberryComponentsMui';

import Typography from "@mui/material/Typography";
import MuiLink from '@mui/material/Link';

import { Link as GatsbyLink } from "gatsby";

import propertyClean from './propertyClean';

import AreaInfo from '@myaccount/property/components/areaInfo';
import Rooms from '@myaccount/property/components/rooms';
import SaveProperty from './SaveProperty';
import BookViewing from './BookViewing';
import MakeAnOffer from './MakeAnOffer';
import CreateTenancy from './CreateTenancy';
import RemoveProperty from './RemoveProperty';
import FlagBid from './FlagBid';
import ArchivePropertyPersona from '@myaccount/property/components/ArchivePropertyPersona';
import CurrencyFormat from './CurrencyFormat';
import VirtualTour from '@myaccount/property/components/virtualTour';
import VideoTour from '@myaccount/property/components/videoTour';
import CarouselImages from '@myaccount/property/components/images';
import { isSold } from '@myaccount/property/utils';

import defaults from './defaults';


//  Gatsby Link component to use MUI style and props
export const LinkItem = React.forwardRef((props,ref) => (
    <MuiLink component={GatsbyLink}
             ref={ref}
             {...props}
    />
));

export const PropertyResult = withSubtheme( (props) => {
    let {
        theme,
        type, // ignored (see original index.jsx in Components)
        property, _property,
        image_processed,
        clean,
        className,
        listType,
        reloadDataFun,
        ...rest
    } = props;

    const noActionStatus = get(props, 'noActionStatus', [])
    const noAction = (noActionStatus.length > 0 && noActionStatus.includes(property.status))

    // Refactor and clean property data?
    if (undefined === clean) clean = true;
    if (clean) property = propertyClean(property, theme);

    if (undefined === image_processed) image_processed = false;

    // Get props for the individual parts
    let _props = deepmerge(
        defaults,
        {
            "container": theme.getProp('container') ?? {},
            "header": theme.getProp('header') ?? {},
            "article": theme.getProp('article') ?? {},
            "aside": theme.getProp('aside') ?? {},
            "media": theme.getProp('media') ?? {}
        },
        theme.getProp('props') ?? {},
        rest
    );

    property = _property ?
        theme.interpolate(_property, {..._props, theme}) :
        property ?? {};

    //const classes = useStyles(_props);
    const jss = Object.entries(_props).reduce( (a,[zone,zoneProps]) => {
        if (zoneProps?.sx) a[zone] = zoneProps.sx;
        return a;
    }, {});

    //const classes = makeStyles(jss, {name:"PropertyResult"})();
    const classes = [];

    // @todo These should be moved to sub-components, to allow a more
    // modular building of the card, but that's a bigger task.
    //
    // Prepare all components
    const typoFn = (item, fn) => (itemProps) => {
        const { variant, component, color, include, ...rest } = itemProps;
        return (
            <Typography variant={variant ?? _props[`${item}Variant`]}
                        component={component ?? _props[`${item}Component`]}
                        color={color ?? _props[`${item}Color`]}
                        className={`propertyresult-${item}`}
                        {...rest}
            >{
                fn()
            }</Typography>
        );
    };


    // Prepare content
    const entries = {
        "summary":     typoFn("summary",     () => property.summary),
        "description": typoFn("description", () => property.description),
    };

    // Convenience function for prep of content entries
    const compoFn = (label, Component, rest) => {
        entries[label] = typoFn(label, () => <Component property={property} theme={theme} {...rest} />);
    };

    // Generate link
    const linkGenFun = theme.getProp('linkFormat');
    if (linkGenFun) {
        property.link = linkGenFun.values.call(property, property);
    }

    if (noAction) {
        property.link = '';
    }

    // Linkify is a component that'll wrap a LinkItem around a thing if
    // there is a link.  If there is no link, it'll just pass it through.
    let Linkify = ({children, ...linkProps}) => <>{children}</>;
    if (property.link) {
        const target = linkGenFun?.target;
        Linkify = ({children, ...linkProps}) => (
            <LinkItem className="propertyresult-link-image"
                      to={property.link}
                      target={target}
                      {...linkProps}>
                {children}
            </LinkItem>
        );
    }

    // Address processing
    const addrFn = () => <Linkify><AddressFormat property={property} /></Linkify>;
    entries.address = typoFn("address", addrFn);

    // Price formatting
    const priceFn = () => {
        if (!property.price) return false;

        const price = property.price ? Math.round(property.price) : 0;
        const price_qualifier = property?.price_qualifier || '';
        return (
            <CurrencyFormat value={price}
                            search_type={property.search_type}
                            rent_frequency={property.letting_info?.rentFrequency}
                            price_qualifier={`${price_qualifier} `}
            />
        );
    };
    entries.price = typoFn("price", priceFn);

    entries.offer = typoFn("offerText", () => property.offerText);

    entries.flag = typoFn("flag", () => {
        if(isSold(props?.flag?.status, property?.status)) {
            return (
                <span>
                    {property.status}
                </span>
            );
        }
        return '';
    });

    compoFn("areaInfo",       AreaInfo);
    compoFn("rooms",          Rooms);
    compoFn("bookviewing",    BookViewing);
    compoFn("save",           SaveProperty, {type:"property", flag:props?.flag, noAction:noAction});
    compoFn("makeoffer",      MakeAnOffer);
    compoFn("tenancy",        CreateTenancy);
    compoFn("removeProperty", RemoveProperty);
    compoFn("flagBid", FlagBid);
    compoFn("removePropertyPersona", ArchivePropertyPersona, { listType: listType, reloadDataFun:reloadDataFun });
    compoFn("360tour",        VirtualTour, {virtual: property.virtual_tour});
    compoFn("videoTour",      VideoTour, {video: property.video_tour});

    // Construct content zones: header, article, etc.
    const components = {};
    Object.entries(_props).forEach( ([entry, _entryProps]) => {

        // Only process things that look like zones in propertyresult.props
        if ('object' !== typeof _entryProps) return;

        let {in:entryIn, ...entryProps} = _entryProps;

        // If 'include' is false, skip it
        if (undefined !== entryProps.include && ! entryProps.include) return;

        // Need content to render
        if (undefined === entries[entry]) return;
        let fn = entries[entry];

        // If the content is set as in another zone, move it
        let zone = entryIn ?? entry;

        // Decide role
        let role;
        if (entryProps.role)
            role = entryProps.role;
        else if (-1 !== ['header', 'media', 'article', 'aside'].indexOf(zone))
            role = zone;
        else
            role = undefined;

        // Clear component list for the zone we're adding to
        if (undefined === components[zone])
            components[zone] = [];

        // Render the component
        components[zone].push(fn(entryProps));
    });

    // Determine image URL; @todo not sure what precedence this should
    // have.
    let image_url;
    if (get(property, 'images[0].url', false)) //  thumbnail for phase 1
        image_url = property.images?.[0]?.url;
    if (!image_url)
        image_url = property.src;
    if (!image_url)
        image_url = property.thumbnail;
    if (!image_url) {
        image_url = property.images?.[0]?.srcUrl;
        image_processed = true;
    }
    if (!image_url)
        image_url = `${process.env.GATSBY_NO_IMAGE}`;

    let processedImages = JSON.stringify({});
    if (property?.imagetransforms?.images_Transforms) {
        processedImages = property?.imagetransforms?.images_Transforms;
    }

    const searchStr = GGFX_ENV.replace('i.', 'x.');

    // check if it is already processed image or not
    if (image_url.indexOf(searchStr) > -1 || image_url.indexOf('assets.reapit') > -1)
        image_processed = true

    // TODO - delete this when real data comes from property results
    let images = property.images;
    if (!property.images || property.images.length === 0) {
        images = [
            {url: image_url}
        ]
    }

    const dummyVar = {
        ...property,
        images: images
    }
    const imgComp = theme.getProp('imageComponent');
    // Prepare image/media component
    let {mediaClassName, imageWidth, imageHeight, /*...mediaRest*/} = _props.media ?? {};
    components.media = components.media ?? [];
    if ("carousel" === imgComp) {
        components.media.push(
            <CarouselImages property={dummyVar} linkify={Linkify} disableLightbox={true} />
        )
    } else {
        components.media.push(
            <Linkify>
                <>
                    {image_processed ? (
                        <Image src={image_url}
                               className={clsx(mediaClassName, classes.media, "propertyresult-image")}
                               width={imageWidth}
                               height={imageHeight}
                               group={_props.media.group}
                        />
                    ) : (
                        <ImageTransform imagesources={image_url} renderer="srcSet" imagename="property.images.thumbnail"
                                        attr={{ alt: 'Property image', className:clsx(mediaClassName, classes.media, "propertyresult-image")} }
                                        imagetransformresult={processedImages} id={property.id} testparam={true} />
                    )}
                </>
            </Linkify>
        )
    }

    const getOption = (x) => _props[x];

    const Zone = ({zone, children, ...props}) => {
        let zoneProps = deepmerge(
            props,
            _props[zone],
            props
        );

        let className = `${clsx(classes[zone], `propertyresult-${zone}`)} `
        if (get(props, 'className', ''))
            className += props.className;

        return (
            <Flexbox {...zoneProps}
                     className={className}>
                {children}
            </Flexbox>
        );
    };

    return (
        <Zone item container
              zone="container"
              className={`${noAction ? 'disabled-zone' : ''} ${clsx(classes.root,"propertyresult")}`}
        >
            {
                _props.container.order.map( z => (
                    <Zone item zone={z} key={`zone-${z}`} children={components[z]} />
                ))
            }
        </Zone>
    );
}, 'propertyresult');

export default PropertyResult;
