// Turns out this is about 3x faster than refactorObject_alt
export const refactorObject =
    (obj, map) => Object.fromEntries(Object.entries(obj).map(
        tuple => [map[tuple[0]] ?? tuple[0], tuple[1]]
    ));

export const refactorObject_alt =
    (obj, map) => Object.fromEntries(obj).reduce(
        (a,c) => ({...a, [map[c[0]] ?? c[0]]: c[1]}),
        {}
    );


export default refactorObject;
