export default {
    "props": {
        "btnLabelProps": "",
        "maxDialogW": "sm",
        "modalTitle": "Do you want to archive this property?",
        "modalSubTitle": "You will ﻿find this property in the Archived Properties section.",
        "unarchived": {
            "modalTitle": "Do you want to unarchive this property?",
            "modalSubTitle": "You will ﻿find this property in the All Properties section."
        },
        "btnProps": {
            "sx": (theme) => ({
                "padding":theme.spacing(2.3, 1),
                "fontWeight":"600",
                "&:hover": {
                    "background":"none",
                    "opacity":"0.7",
                },
                [theme.breakpoints.up('md')]:{
                    "padding":theme.spacing(2.3, 1),
                }
            })
        }
    },

}
